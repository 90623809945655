<template>
  <div>
    <form @submit.prevent="getSearchResults()" class="flex flex-row">
      <input
        type="text"
        class="input min-w-0 lg:text-lg rounded-lg py-3 px-4 mr-2"
        placeholder="Search ideas..."
        v-model="term"
      />
      <button type="submit" class="btn-main px-5 py-3 lg:text-lg rounded-lg">
        <svg
          class="w-6 h-6 md:mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
        <span class="hidden md:block">{{ $t("Search") }}</span>
      </button>
    </form>
    <!-- Search Filter -->
    <div class="grid grid-cols-1 lg:grid-cols-7 gap-7 mt-8 mb-8">
      <div class="col-span-1 lg:col-span-2">
        <form @submit.prevent="getSearchResults()">
          <input type="hidden" name="utf8" value="✓" />

          <div class="flex flex-col items-start justify-start gap-5">
            <div class="w-full">
              <h5 class="text-neutral-800 font-semibold">{{ $t("Status") }}</h5>
              <div class="ml-1 mb-4">
                <input
                  class="custom-checkbox"
                  type="checkbox"
                  @click="toggleStatusesSelection()"
                  id="status-selection-toggle-checkbox"
                  v-model="isAllStatusesSelected"
                />
                <label
                  for="status-selection-toggle-checkbox"
                  class="text-project-500"
                >
                  {{
                    isAllStatusesSelected
                      ? $t("Deselect all")
                      : $t("Select all")
                  }}
                </label>
              </div>
              <div v-for="status in statuses" :key="status.id">
                <div class="ml-1">
                  <input
                    class="custom-checkbox"
                    name="post[status][]"
                    type="checkbox"
                    :id="status.id"
                    :value="status.id"
                    v-model="checkedStatuses"
                  />
                  <label :for="status.id"> {{ status.name }}</label>
                </div>
              </div>
            </div>

            <div class="w-full">
              <h5 class="text-neutral-800 font-semibold">{{ $t("Board") }}</h5>
              <div class="ml-1 mb-4">
                <input
                  class="custom-checkbox"
                  type="checkbox"
                  @click="toggleBoardsSelection()"
                  id="board-selection-toggle-checkbox"
                  v-model="isAllBoardsSelected"
                />
                <label
                  for="board-selection-toggle-checkbox"
                  class="text-project-500"
                >
                  {{
                    isAllBoardsSelected ? $t("Deselect all") : $t("Select all")
                  }}
                </label>
              </div>
              <div v-for="board in boards" :key="board.id">
                <div class="ml-1">
                  <input
                    class="custom-checkbox"
                    name="post[board][]"
                    type="checkbox"
                    :id="board.id"
                    :value="board.id"
                    v-model="checkedBoards"
                  />
                  <label :for="board.id"> {{ board.name }} </label>
                </div>
              </div>
            </div>

            <div v-if="isAdmin && false">
              <h5 class="text-neutral-800 font-semibold mt-4">Tags</h5>
              <div v-for="tag in tags" :key="tag.id">
                <div class="ml-1">
                  <input
                    type="checkbox"
                    :id="tag.id"
                    name="post[tag][]"
                    v-model="checkedTags"
                    :value="tag.id"
                    class="custom-checkbox"
                  />
                  <label :for="tag.id"> {{ tag.name }} </label>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-7">
            <button type="submit" class="btn lg:w-full justify-center">
              {{ $t("Filter results") }}
            </button>
          </div>
        </form>
      </div>

      <div class="col-span-1 lg:col-span-5">
        <p
          class="text-xl text-center"
          v-if="term.length == 0 && submitted == false && posts.length == 0"
        >
          {{ $t("Please enter your search term in the field above") }}
        </p>

        <p
          class="text-xl text-center mt-2"
          v-if="posts.length === 0 && submitted === true"
        >
          {{ $t("There are no ideas matching") }} {{ term }}
        </p>

        <div
          v-if="posts.length > 0 && submitted === true"
          class="bg-white rounded-lg border mb-8 pb-4"
        >
          <div class="px-4 py-4 border-b rounded-t-lg border-neutral-200 mb-4">
            <div class="flex items-center justify-between">
              <span class="text-neutral-800 text-lg">Search results</span>
              <span class="text-neutral-700"
                >{{ posts.length }}
                {{ posts.length > 1 ? "ideas" : "idea" }}</span
              >
            </div>
          </div>
          <div class="flex flex-col gap-4">
          <div
            is="post"
            v-for="post in filteredPosts"
            :originalPost="post"
            :currentUser="JSON.parse(currentUser)"
            :current-project-admin="isAdmin"
            :key="post.id"
            :allow-anonymous="allowAnonymouslyVote"
            :status-name="JSON.parse(statusNames)"
            :show-board="true"
            :id="post.id"
            :class="post.id"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Post from "../post";

export default {
  props: {
    searchUrl: String,
    allStatuses: String,
    isAdmin: Boolean,
    allBoards: String,
    currentUser: String,
    statusNames: String,
    postTags: String,
    allowAnonymouslyVote: Boolean,
    allPosts: String,
    searchTerm: String,
    currentUserVotes: Array,
  },
  components: {
    Post,
  },
  data() {
    return {
      term: this.searchTerm,
      statuses: JSON.parse(this.allStatuses),
      boards: JSON.parse(this.allBoards),
      tags: JSON.parse(this.postTags),
      checkedStatuses: [],
      checkedBoards: [],
      checkedTags: [],
      posts: JSON.parse(this.allPosts),
      isAllStatusesSelected: false,
      isAllBoardsSelected: false,
      submitted: true,
    };
  },
  created() {
    this.toggleStatusesSelection();
    this.toggleBoardsSelection();

    // listen for history changes
    window.addEventListener("popstate", () => {
      this.term = new URLSearchParams(window.location.search).get("term");
      this.getSearchResults();
    });
  },
  computed: {
    filteredPosts: {
      get() {
        return this.posts;
      },
      set(value) {
        this.posts = value;
      },
    },
  },
  methods: {
    toggleStatusesSelection() {
      if (this.isAllStatusesSelected) {
        this.checkedStatuses = [];
        this.isAllStatusesSelected = false;
      } else {
        this.statuses.forEach((element) => {
          this.checkedStatuses.push(element.id);
        });
        this.checkedStatuses = [...new Set(this.checkedStatuses)];
        this.isAllStatusesSelected = true;
      }
    },
    toggleBoardsSelection() {
      if (this.isAllBoardsSelected) {
        this.checkedBoards = [];
        this.isAllBoardsSelected = false;
      } else {
        this.boards.forEach((element) => {
          this.checkedBoards.push(element.id);
        });
        this.checkedBoards = [...new Set(this.checkedBoards)];
        this.isAllBoardsSelected = true;
      }
    },
    async getSearchResults() {
      Rails.ajax({
        url:
          this.searchUrl +
          `/?term=${this.term}&status[]=${this.checkedStatuses}&board[]=${this.checkedBoards}`,
        type: "GET",
        success: (data) => {
          var posts = JSON.parse(data.posts);
          posts.forEach((post) => {
            post.current_user_voted_for = data.current_user_votes.includes(
              post.id
            );
          });
          this.filteredPosts = posts;
          this.submitted = true;

          // replace term in the URL
          history.pushState(
            {},
            null,
            `${window.location.origin}${window.location.pathname}?term=${this.term}`
          );
        },
        error: (data, status) => {},
      });
    },
  },
  watch: {
    checkedStatuses() {
      if (this.checkedStatuses.length < this.statuses.length) {
        this.isAllStatusesSelected = false;
      } else if (this.checkedStatuses.length == this.statuses.length) {
        this.isAllStatusesSelected = true;
      }
    },
    checkedBoards() {
      if (this.checkedBoards.length < this.boards.length) {
        this.isAllBoardsSelected = false;
      } else if (this.checkedBoards.length == this.boards.length) {
        this.isAllBoardsSelected = true;
      }
    },
    term() {
      this.submitted = false;
    },
  },
};
</script>
